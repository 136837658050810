// import React from "react";
// import Slider from "react-slick";
// import VedioCert from "../Assets/VedioCert.png";
// import SEOCert from "../Assets/SEOCert.png";
// import FreeCert from "../Assets/FreeCert.png";
// import interCert from "../Assets/interCert.jpg";
// import atomCert from "../Assets/atomCert.jpg";

// function CertificatesSlider() {
//   const settings = {
//     className: "center",
//     centerMode: true,
//     infinite: true,
//     centerPadding: "200px", // Controls visibility of left/right slides
//     slidesToShow: 1,
//     speed: 500,
//     focusOnSelect: true,
//     autoplay: true,
//     dots: true,
//   };

//   return (
//     <div className="slider-container p-4">
//       {/* Tailwind padding around the slider */}
//       <Slider {...settings}>
//         <div className="px-4">
//           {/* Tailwind padding between slides */}
//           <img
//             src={VedioCert}
//             alt="VedioCert"
//             className="w-full  rounded-3xl shadow-lg"
//           />
//         </div>
//         <div className="px-4">
//           <img
//             src={interCert}
//             alt="interCert"
//             className="w-full  rounded-3xl shadow-lg"
//           />
//         </div>
//         <div className="px-4">
//           <img
//             src={FreeCert}
//             alt="FreeCert"
//             className="w-full  rounded-3xl shadow-lg"
//           />
//         </div>
//         <div className="px-4">
//           <img
//             src={SEOCert}
//             alt="SEOCert"
//             className="w-full  rounded-3xl shadow-lg"
//           />
//         </div>
//         <div className="px-4">
//           <img
//             src={atomCert}
//             alt="atomCert"
//             className="w-full  rounded-3xl shadow-lg"
//           />
//         </div>
//       </Slider>
//     </div>
//   );
// }

// export default CertificatesSlider;

import React from "react";
import Slider from "react-slick";
import VedioCert from "../Assets/VedioCert.png";
import SEOCert from "../Assets/SEOCert.png";
import FreeCert from "../Assets/FreeCert.png";
import interCert from "../Assets/interCert.jpg";
import atomCert from "../Assets/atomCert.jpg";

function CertificatesSlider() {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "200px", // Default for larger screens
    slidesToShow: 1,
    speed: 500,
    focusOnSelect: true,
    autoplay: true,
    dots: true,
    responsive: [
      {
        breakpoint: 768, // For tablets and smaller screens
        settings: {
          centerPadding: "50px", // Adjusted to avoid excessive padding
          slidesToShow: 1, // Show only one slide
        },
      },
      {
        breakpoint: 480, // For mobile screens
        settings: {
          centerPadding: "20px", // Reduced padding for mobile screens
          slidesToShow: 1, // Show only one slide
          centerMode: false, // Disable centerMode to avoid issues on small screens
        },
      },
      {
        breakpoint: 320, // For extra small mobile screens
        settings: {
          centerPadding: "10px", // Very small padding for extra small screens
          slidesToShow: 1, // Show only one slide
          centerMode: false, // Disable centerMode for better layout
        },
      },
    ],
  };

  return (
    <div className="slider-container p-4">
      {/* Tailwind padding around the slider */}
      <Slider {...settings}>
        <div className="px-4">
          {/* Tailwind padding between slides */}
          <img
            src={VedioCert}
            alt="VedioCert"
            className="w-full  rounded-3xl shadow-lg"
          />
        </div>
        <div className="px-4">
          <img
            src={interCert}
            alt="interCert"
            className="w-full  rounded-3xl shadow-lg"
          />
        </div>
        <div className="px-4">
          <img
            src={FreeCert}
            alt="FreeCert"
            className="w-full  rounded-3xl shadow-lg"
          />
        </div>
        <div className="px-4">
          <img
            src={SEOCert}
            alt="SEOCert"
            className="w-full  rounded-3xl shadow-lg"
          />
        </div>
        <div className="px-4">
          <img
            src={atomCert}
            alt="atomCert"
            className="w-full  rounded-3xl shadow-lg"
          />
        </div>
      </Slider>
    </div>
  );
}

export default CertificatesSlider;
