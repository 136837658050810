// import React from "react";

// const Resume = () => {
//   return (
//     <div className="font font-Poppins bg-[#212529] min-h-screen pb-8">
//       {/* text overlay effect of main heading*/}
//       <div className="flex flex-col items-center relative pt-20">
//         {/* Background Text */}
//         <h1 className="absolute text-6xl md:text-9xl font-semibold text-white opacity-5">
//           SUMMARY
//         </h1>
//         <div className="relative flex flex-col items-center mt-5 md:mt-12">
//           {/* Foreground Text */}
//           <h1 className="text-2xl md:text-4xl font-semibold text-gray-100">
//             Resume
//           </h1>
//           {/* Custom Border */}
//           <div className="md:w-28 w-14 md:h-[4px] h-[2px] bg-orange-600 mt-2"></div>
//         </div>
//       </div>
//       {/* main content section */}
//       <div></div>
//     </div>
//   );
// };

// export default Resume;

// import React from "react";
// import { FiDownload } from "react-icons/fi";

// const Resume = () => {
//   return (
//     <div className="font font-Poppins bg-[#212529] min-h-screen pb-8 text-gray-100">
//       {/* Text overlay effect of main heading */}
//       <div className="flex flex-col items-center relative pt-20">
//         {/* Background Text */}
//         <h1 className="absolute text-6xl md:text-9xl font-semibold text-white opacity-5">
//           SUMMARY
//         </h1>
//         <div className="relative flex flex-col items-center mt-5 md:mt-12">
//           {/* Foreground Text */}
//           <h1 className="text-2xl md:text-4xl font-semibold text-gray-100">
//             Resume
//           </h1>
//           {/* Custom Border */}
//           <div className="md:w-28 w-14 md:h-[4px] h-[2px] bg-orange-600 mt-2"></div>
//         </div>
//       </div>

//       {/* Main content section */}
//       <div className="px-8 md:px-20 mt-12 space-y-12">
//         {/* Education Section */}
//         {/* <section id="education">
//           <h2 className="text-xl md:text-2xl font-semibold text-orange-600 mb-6">
//             My Education
//           </h2>
//           <div className="space-y-6">
//             <div className="bg-[#2d3033] p-4 rounded-md">
//               <h3 className="text-lg font-semibold">
//                 Full Stack Web Development
//               </h3>
//               <p className="text-gray-400">Digitalized Solutions | 2024</p>
//               <p>
//                 Completed an intensive bootcamp covering full stack development,
//                 with sessions in English language, communication skills, and
//                 front-end development.
//               </p>
//             </div>
//           </div>
//         </section> */}
//         <section id="education">
//           <h2 className="text-xl md:text-2xl font-semibold text-orange-600 mb-6">
//             Education
//           </h2>
//           <div className="space-y-6">
//             <div className="bg-[#2d3033] p-4 rounded-md">
//               <h3 className="text-lg font-semibold">BS (CS)</h3>
//               <p className="text-gray-400">
//                 Virtual University of Pakistan | Sep 2012 – Sep 2019
//               </p>
//               <p>CGPA: 2.98/4.0</p>
//             </div>
//             <div className="bg-[#2d3033] p-4 rounded-md">
//               <h3 className="text-lg font-semibold">
//                 Full Stack Development Trainee
//               </h3>
//               <p className="text-gray-400">Atomcamp | Feb 2024 – April 2024</p>
//               <p>
//                 As a trainee at AtomCamp Bootcamp, I actively engaged in
//                 learning and implementing various technologies including React,
//                 Node.js, Bootstrap, Tailwind CSS, and API integration. Hands-on
//                 projects included a counter app, to-do list, school website, gym
//                 design, 3D image, and weather app, showcasing practical
//                 applications of my acquired skills.
//               </p>
//             </div>
//           </div>
//         </section>

//         {/* Experience Section */}
//         <section id="experience">
//           <h2 className="text-xl md:text-2xl font-semibold text-orange-600 mb-6">
//             My Experience
//           </h2>
//           <div className="space-y-6">
//             <div className="bg-[#2d3033] p-4 rounded-md">
//               <h3 className="text-lg font-semibold">Frontend Developer</h3>
//               <p className="text-gray-400">
//                 Digitalized Solutions | July 2024 - Present
//               </p>
//               <p>
//                 Promoted to full-time Frontend Developer after interning since
//                 April 2024. Developed dynamic web applications using React and
//                 Next.js, gained experience in backend development, and
//                 contributed to multiple projects.
//               </p>
//             </div>
//             <div className="bg-[#2d3033] p-4 rounded-md">
//               <h3 className="text-lg font-semibold">
//                 Frontend Developer Intern
//               </h3>
//               <p className="text-gray-400">
//                 Digitalized Solutions | April 2024 - July 2024
//               </p>
//               <p>
//                 Worked on hands-on development projects using React and Next.js,
//                 focusing on creating responsive, dynamic web applications and
//                 gaining foundational experience in front-end development.
//               </p>
//             </div>
//           </div>
//         </section>

//         {/* skills grid */}
//         <section id="skills">
//           <h2 className="text-xl md:text-2xl font-semibold text-orange-600 mb-6">
//             My Skills
//           </h2>
//           <div className="grid grid-cols-2 gap-6 bg-[#2d3033] p-4 rounded-md">
//             {/* skill 1 */}
//             <div class="relative   text-white">
//               {/* <!-- Text above the bar --> */}
//               <div className="flex justify-between mb-1">
//                 <span className=" font-semibold">Web Design</span>
//                 <span className=" font-semibold">85%</span>
//               </div>
//               {/* <!-- Progress bar --> */}
//               <div className="h-2 rounded-full overflow-hidden bg-gray-300">
//                 <div
//                   className="h-full rounded-full bg-orange-600"
//                   style={{ width: "85%" }}
//                 ></div>
//               </div>
//             </div>
//             {/* skill 2 */}
//             <div class="relative   text-white">
//               {/* <!-- Text above the bar --> */}
//               <div className="flex justify-between mb-1">
//                 <span className=" font-semibold">HTML/CSS</span>
//                 <span className=" font-semibold">70%</span>
//               </div>
//               {/* <!-- Progress bar --> */}
//               <div className="h-2 rounded-full overflow-hidden bg-gray-300">
//                 <div
//                   className="h-full rounded-full bg-orange-600"
//                   style={{ width: "85%" }}
//                 ></div>
//               </div>
//             </div>
//             {/* skill 3 */}
//             <div class="relative   text-white">
//               {/* <!-- Text above the bar --> */}
//               <div className="flex justify-between mb-1">
//                 <span className=" font-semibold">JavaScript</span>
//                 <span className=" font-semibold">85%</span>
//               </div>
//               {/* <!-- Progress bar --> */}
//               <div className="h-2 rounded-full overflow-hidden bg-gray-300">
//                 <div
//                   className="h-full rounded-full bg-orange-600"
//                   style={{ width: "85%" }}
//                 ></div>
//               </div>
//             </div>
//             {/* skill 4 */}
//             <div class="relative   text-white">
//               {/* <!-- Text above the bar --> */}
//               <div className="flex justify-between mb-1">
//                 <span className=" font-semibold">React.js</span>
//                 <span className=" font-semibold">95%</span>
//               </div>
//               {/* <!-- Progress bar --> */}
//               <div className="h-2 rounded-full overflow-hidden bg-gray-300">
//                 <div
//                   className="h-full rounded-full bg-orange-600"
//                   style={{ width: "85%" }}
//                 ></div>
//               </div>
//             </div>
//             {/* skill 5 */}
//             <div class="relative   text-white">
//               {/* <!-- Text above the bar --> */}
//               <div className="flex justify-between mb-1">
//                 <span className=" font-semibold">Next.js</span>
//                 <span className=" font-semibold">95%</span>
//               </div>
//               {/* <!-- Progress bar --> */}
//               <div className="h-2 rounded-full overflow-hidden bg-gray-300">
//                 <div
//                   className="h-full rounded-full bg-orange-600"
//                   style={{ width: "85%" }}
//                 ></div>
//               </div>
//             </div>
//             {/* skill 6 */}
//             <div class="relative   text-white">
//               {/* <!-- Text above the bar --> */}
//               <div className="flex justify-between mb-1">
//                 <span className=" font-semibold">Tailwind CSS</span>
//                 <span className=" font-semibold">95%</span>
//               </div>
//               {/* <!-- Progress bar --> */}
//               <div className="h-2 rounded-full overflow-hidden bg-gray-300">
//                 <div
//                   className="h-full rounded-full bg-orange-600"
//                   style={{ width: "85%" }}
//                 ></div>
//               </div>
//             </div>
//           </div>
//         </section>
//         {/* cv button */}
//         <div className="flex items-center justify-center">
//           <button className="hover:bg-orange-600 border-orange-600 border-2 mt-6 rounded-full px-8 py-3 flex justify-center items-center gap-2">
//             Download CV
//             <FiDownload />
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Resume;

import React from "react";
import { FiDownload } from "react-icons/fi";

const Resume = () => {
  return (
    <div className="font font-Poppins bg-[#212529] min-h-screen pb-8 text-gray-100">
      {/* Text overlay effect of main heading */}
      <div className="flex flex-col items-center relative pt-20">
        {/* Background Text */}
        <h1 className="absolute text-6xl md:text-9xl font-semibold text-white opacity-5">
          SUMMARY
        </h1>
        <div className="relative flex flex-col items-center mt-5 md:mt-12">
          {/* Foreground Text */}
          <h1 className="text-2xl md:text-4xl font-semibold text-gray-100">
            Resume
          </h1>
          {/* Custom Border */}
          <div className="md:w-28 w-14 md:h-[4px] h-[2px] bg-orange-600 mt-2"></div>
        </div>
      </div>

      {/* Main content section */}
      <div className="px-8 md:px-20 mt-12 space-y-12">
        {/* Education Section */}
        <div className="bg-[#212529] text-gray-100">
          <h2 className="text-xl md:text-2xl font-semibold text-orange-600 mb-6">
            Education
          </h2>

          {/* Grid Container */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Card 1 */}
            <div className="bg-[#2d3033] p-6 rounded-md shadow-md text-gray-100 space-y-2">
              {/* Year Badge */}
              <div className="inline-block bg-orange-600 text-white text-sm px-3 py-1 rounded-md font-semibold">
                2019
              </div>

              {/* Title and Subtitle */}
              <h3 className="text-xl font-semibold">BS (CS)</h3>
              <p className="text-orange-500">Virtual University of Pakistan</p>

              {/* Description */}
              <p className="text-gray-400">
                Completed a comprehensive degree program with a CGPA of
                2.98/4.0.
              </p>
            </div>

            {/* Card 2 */}
            <div className="bg-[#2d3033] p-6 rounded-md shadow-md text-gray-100 space-y-2">
              {/* Year Badge */}
              <div className="inline-block bg-orange-600 text-white text-sm px-3 py-1 rounded-md font-semibold">
                2024
              </div>

              {/* Title and Subtitle */}
              <h3 className="text-xl font-semibold">
                Full Stack Development Trainee
              </h3>
              <p className="text-orange-500">Atomcamp</p>

              {/* Description */}
              <p className="text-gray-400">
                Engaged in learning and implementing various technologies,
                including React, Node.js, and Tailwind CSS.
              </p>
            </div>

            {/* Add more cards as needed, following the same structure */}
          </div>
        </div>
        {/* Experience Section */}
        <div className="bg-[#212529] text-gray-100">
          <h2 className="text-xl md:text-2xl font-semibold text-orange-600 mb-6">
            My Experience
          </h2>

          {/* Grid Container */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Card 1 */}
            <div className="bg-[#2d3033] p-6 rounded-md shadow-md text-gray-100 space-y-2">
              {/* Year Badge */}
              <div className="inline-block bg-orange-600 text-white text-sm px-3 py-1 rounded-md font-semibold">
                July 2024 - Present
              </div>

              {/* Title and Subtitle */}
              <h3 className="text-xl font-semibold">Frontend Developer</h3>
              <p className="text-orange-500">Digitalized Solutions</p>

              {/* Description */}
              <p className="text-gray-400">
                Promoted to full-time Frontend Developer after interning since
                April 2024. Developed dynamic web applications using React and
                Next.js, gained experience in backend development, and
                contributed to multiple projects.
              </p>
            </div>

            {/* Card 2 */}
            <div className="bg-[#2d3033] p-6 rounded-md shadow-md text-gray-100 space-y-2">
              {/* Year Badge */}
              <div className="inline-block bg-orange-600 text-white text-sm px-3 py-1 rounded-md font-semibold">
                April 2024 - July 2024
              </div>

              {/* Title and Subtitle */}
              <h3 className="text-xl font-semibold">
                Frontend Developer Intern
              </h3>
              <p className="text-orange-500">Digitalized Solutions</p>

              {/* Description */}
              <p className="text-gray-400">
                Worked on hands-on development projects using React and Next.js,
                focusing on creating responsive, dynamic web applications and
                gaining foundational experience in front-end development.
              </p>
            </div>

            {/* Add more cards as needed, following the same structure */}
          </div>
        </div>

        {/* skills grid */}
        <section id="skills">
          <h2 className="text-xl md:text-2xl font-semibold text-orange-600 mb-6">
            My Skills
          </h2>
          <div className="grid grid-cols-2 gap-6 bg-[#2d3033] p-4 rounded-md">
            {/* skill 1 */}
            <div class="relative   text-white">
              {/* <!-- Text above the bar --> */}
              <div className="flex justify-between mb-1">
                <span className=" font-semibold">Web Design</span>
                <span className=" font-semibold">85%</span>
              </div>
              {/* <!-- Progress bar --> */}
              <div className="h-2 rounded-full overflow-hidden bg-gray-300">
                <div
                  className="h-full rounded-full bg-orange-600"
                  style={{ width: "85%" }}
                ></div>
              </div>
            </div>
            {/* skill 2 */}
            <div class="relative   text-white">
              {/* <!-- Text above the bar --> */}
              <div className="flex justify-between mb-1">
                <span className=" font-semibold">HTML/CSS</span>
                <span className=" font-semibold">70%</span>
              </div>
              {/* <!-- Progress bar --> */}
              <div className="h-2 rounded-full overflow-hidden bg-gray-300">
                <div
                  className="h-full rounded-full bg-orange-600"
                  style={{ width: "85%" }}
                ></div>
              </div>
            </div>
            {/* skill 3 */}
            <div class="relative   text-white">
              {/* <!-- Text above the bar --> */}
              <div className="flex justify-between mb-1">
                <span className=" font-semibold">JavaScript</span>
                <span className=" font-semibold">85%</span>
              </div>
              {/* <!-- Progress bar --> */}
              <div className="h-2 rounded-full overflow-hidden bg-gray-300">
                <div
                  className="h-full rounded-full bg-orange-600"
                  style={{ width: "85%" }}
                ></div>
              </div>
            </div>
            {/* skill 4 */}
            <div class="relative   text-white">
              {/* <!-- Text above the bar --> */}
              <div className="flex justify-between mb-1">
                <span className=" font-semibold">React.js</span>
                <span className=" font-semibold">95%</span>
              </div>
              {/* <!-- Progress bar --> */}
              <div className="h-2 rounded-full overflow-hidden bg-gray-300">
                <div
                  className="h-full rounded-full bg-orange-600"
                  style={{ width: "85%" }}
                ></div>
              </div>
            </div>
            {/* skill 5 */}
            <div class="relative   text-white">
              {/* <!-- Text above the bar --> */}
              <div className="flex justify-between mb-1">
                <span className=" font-semibold">Next.js</span>
                <span className=" font-semibold">95%</span>
              </div>
              {/* <!-- Progress bar --> */}
              <div className="h-2 rounded-full overflow-hidden bg-gray-300">
                <div
                  className="h-full rounded-full bg-orange-600"
                  style={{ width: "85%" }}
                ></div>
              </div>
            </div>
            {/* skill 6 */}
            <div class="relative   text-white">
              {/* <!-- Text above the bar --> */}
              <div className="flex justify-between mb-1">
                <span className=" font-semibold">Tailwind CSS</span>
                <span className=" font-semibold">95%</span>
              </div>
              {/* <!-- Progress bar --> */}
              <div className="h-2 rounded-full overflow-hidden bg-gray-300">
                <div
                  className="h-full rounded-full bg-orange-600"
                  style={{ width: "85%" }}
                ></div>
              </div>
            </div>
          </div>
        </section>
        {/* cv button */}
        <div className="flex items-center justify-center">
          <button className="hover:bg-orange-600 border-orange-600 border-2 mt-6 rounded-full px-8 py-3 flex justify-center items-center gap-2">
            Download CV
            <FiDownload />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Resume;
