// import React from "react";

// const Services = () => {
//   return (
//     <div className="font font-Poppins bg-[#343A40] min-h-screen pb-8">
//       {/* text overlay effect of main heading*/}
//       <div className="flex flex-col items-center relative pt-20">
//         {/* Background Text */}
//         <h1 className="absolute text-6xl md:text-9xl font-semibold text-white opacity-5">
//           Services
//         </h1>
//         <div className="relative flex flex-col items-center mt-5 md:mt-12">
//           {/* Foreground Text */}
//           <h1 className="text-2xl md:text-4xl font-semibold text-gray-100">
//             What I Do?
//           </h1>
//           {/* Custom Border */}
//           <div className="md:w-28 w-14 md:h-[4px] h-[2px] bg-orange-600 mt-2"></div>
//         </div>
//       </div>
//       {/* main content section */}
//       <div></div>
//     </div>
//   );
// };

// export default Services;

// import React from "react";
// import {
//   FaLaptopCode,
//   FaPalette,
//   FaUserAlt,
//   FaSearch,
//   FaDatabase,
//   FaPaintBrush,
// } from "react-icons/fa"; // Import icons

// const services = [
//   {
//     title: "Frontend Development",
//     description:
//       "Building responsive and dynamic web applications with React and Next.js for user-friendly interfaces.",
//     icon: <FaLaptopCode className="text-orange-600 text-4xl mb-4" />, // Add icon
//   },
//   {
//     title: "Web Design",
//     description:
//       "Creating visually appealing, responsive web designs with Tailwind CSS and Bootstrap for enhanced usability.",
//     icon: <FaPalette className="text-orange-600 text-4xl mb-4" />,
//   },
//   {
//     title: "UI/UX Design",
//     description:
//       "Designing intuitive interfaces focused on user-centered design principles to enhance user experience.",
//     icon: <FaUserAlt className="text-orange-600 text-4xl mb-4" />,
//   },
//   {
//     title: "SEO & Digital Marketing",
//     description:
//       "Improving website visibility and driving organic traffic through SEO strategies and marketing best practices.",
//     icon: <FaSearch className="text-orange-600 text-4xl mb-4" />,
//   },
//   {
//     title: "Backend Development",
//     description:
//       "Developing RESTful APIs and managing data efficiently with Node.js and MongoDB for backend functionality.",
//     icon: <FaDatabase className="text-orange-600 text-4xl mb-4" />,
//   },
//   {
//     title: "Graphic Design",
//     description:
//       "Creating engaging graphics and animations to enhance brand identity and user engagement.",
//     icon: <FaPaintBrush className="text-orange-600 text-4xl mb-4" />,
//   },
// ];

// const Services = () => {
//   return (
//     <div className="font font-Poppins bg-[#343A40] min-h-screen pb-8">
//       {/* Text overlay effect of main heading */}
//       <div className="flex flex-col items-center relative pt-20">
//         {/* Background Text */}
//         <h1 className="absolute text-6xl md:text-9xl font-semibold text-white opacity-5">
//           Services
//         </h1>
//         <div className="relative flex flex-col items-center mt-5 md:mt-12">
//           {/* Foreground Text */}
//           <h1 className="text-2xl md:text-4xl font-semibold text-gray-100">
//             What I Do?
//           </h1>
//           {/* Custom Border */}
//           <div className="md:w-28 w-14 md:h-[4px] h-[2px] bg-orange-600 mt-2"></div>
//         </div>
//       </div>

//       {/* Main content section */}
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-20 mx-8 md:mx-20">
//         {services.map((service, index) => (
//           <div
//             key={index}
//             className="bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
//           >
//             {service.icon} {/* Display icon */}
//             <h3 className="text-xl font-semibold text-gray-100 mb-2">
//               {service.title}
//             </h3>
//             <p className="text-gray-400">{service.description}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Services;

import React from "react";
import {
  FaLaptopCode,
  FaPalette,
  FaUserAlt,
  FaSearch,
  FaDatabase,
  FaPaintBrush,
} from "react-icons/fa";

// const services = [
//   {
//     title: "Frontend Development",
//     description:
//       "Building responsive and dynamic web applications with React and Next.js for user-friendly interfaces.",
//     icon: <FaLaptopCode className="text-orange-600 text-4xl mb-4" />, // Add icon
//   },
//   {
//     title: "Web Design",
//     description:
//       "Creating visually appealing, responsive web designs with Tailwind CSS and Bootstrap for enhanced usability.",
//     icon: <FaPalette className="text-orange-600 text-4xl mb-4" />,
//   },
//   {
//     title: "UI/UX Design",
//     description:
//       "Designing intuitive interfaces focused on user-centered design principles to enhance user experience.",
//     icon: <FaUserAlt className="text-orange-600 text-4xl mb-4" />,
//   },
//   {
//     title: "SEO & Digital Marketing",
//     description:
//       "Improving website visibility and driving organic traffic through SEO strategies and marketing best practices.",
//     icon: <FaSearch className="text-orange-600 text-4xl mb-4" />,
//   },
//   {
//     title: "Backend Development",
//     description:
//       "Developing RESTful APIs and managing data efficiently with Node.js and MongoDB for backend functionality.",
//     icon: <FaDatabase className="text-orange-600 text-4xl mb-4" />,
//   },
//   {
//     title: "Graphic Design",
//     description:
//       "Creating engaging graphics and animations to enhance brand identity and user engagement.",
//     icon: <FaPaintBrush className="text-orange-600 text-4xl mb-4" />,
//   },
// ];

const Services = () => {
  return (
    <div className="font font-Poppins bg-[#343A40] min-h-screen pb-8">
      {/* Text overlay effect of main heading */}
      <div className="flex flex-col items-center relative pt-20">
        {/* Background Text */}
        <h1 className="absolute text-6xl md:text-9xl font-semibold text-white opacity-5">
          Services
        </h1>
        <div className="relative flex flex-col items-center mt-5 md:mt-12">
          {/* Foreground Text */}
          <h1 className="text-2xl md:text-4xl font-semibold text-gray-100">
            What I Do?
          </h1>
          {/* Custom Border */}
          <div className="md:w-28 w-14 md:h-[4px] h-[2px] bg-orange-600 mt-2"></div>
        </div>
      </div>

      {/* Main content section */}
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-16 px-10 text-white">
        {/* card 1 */}
        <div className="flex items-start space-x-4">
          <div className="bg-[#212529] p-2 rounded-md flex-shrink-0">
            <FaLaptopCode className="text-orange-600 text-4xl" />
          </div>
          <div>
            <h1 className="text-white">Frontend Development</h1>
            <p className="text-gray-400">
              Building responsive and dynamic web applications with React and
              Next.js for user-friendly interfaces.
            </p>
          </div>
        </div>

        {/* card 2 */}
        <div className="flex items-start space-x-4">
          <div className="bg-[#212529] p-2 rounded-md flex-shrink-0">
            <FaPalette className="text-orange-600 text-4xl" />
          </div>
          <div>
            <h1 className="text-white">Web Design</h1>
            <p className="text-gray-400">
              Creating visually appealing, responsive web designs with Tailwind
              CSS and Bootstrap for enhanced usability.
            </p>
          </div>
        </div>
        {/* card 3 */}

        <div className="flex items-start space-x-4">
          <div className="bg-[#212529] p-2 rounded-md flex-shrink-0">
            <FaUserAlt className="text-orange-600 text-4xl" />
          </div>
          <div>
            <h1 className="text-white">UI/UX Design</h1>
            <p className="text-gray-400">
              Designing intuitive interfaces focused on user-centered design
              principles to enhance user experience
            </p>
          </div>
        </div>
        {/* card 4 */}

        <div className="flex items-start space-x-4">
          <div className="bg-[#212529] p-2 rounded-md flex-shrink-0">
            <FaSearch className="text-orange-600 text-4xl" />
          </div>
          <div>
            <h1 className="text-white">SEO & Digital Marketing</h1>
            <p className="text-gray-400">
              Improving website visibility and driving organic traffic through
              SEO strategies and marketing best practices.
            </p>
          </div>
        </div>
        {/* card 5 */}

        <div className="flex items-start space-x-4">
          <div className="bg-[#212529] p-2 rounded-md flex-shrink-0">
            <FaDatabase className="text-orange-600 text-4xl" />
          </div>
          <div>
            <h1 className="text-white">Backend Development</h1>
            <p className="text-gray-400">
              Developing RESTful APIs and managing data efficiently with Node.js
              and MongoDB for backend functionality.
            </p>
          </div>
        </div>
        {/* card 6 */}

        <div className="flex items-start space-x-4">
          <div className="bg-[#212529] p-2 rounded-md flex-shrink-0">
            <FaPaintBrush className="text-orange-600 text-4xl" />
          </div>
          <div>
            <h1 className="text-white">Graphic Design</h1>
            <p className="text-gray-400">
              Creating engaging graphics and animations to enhance brand
              identity and user engagement.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
