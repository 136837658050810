import React from 'react'
import './App.css'
import HomePage from './Components/HomePage'
import  Animatedcurser from './Components/Animatedcurser'



const App = () => {
  return (
    <div className='overflow-x-auto'>
       <Animatedcurser />
     <HomePage />
    </div>
  )
}

export default App