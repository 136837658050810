import React from "react";
import CertificatesSlider from "./CertificatesSlider";

const Certificates = () => {
  return (
    <div className="font font-Poppins bg-[#212529] min-h-screen pb-8">
      {/* text overlay effect of main heading */}
      <div className="flex flex-col items-center relative pt-20 mb-20">
        {/* Background Text */}
        <h1 className="absolute text-6xl md:text-9xl font-semibold text-white opacity-5">
          Achievements
        </h1>
        <div className="relative flex flex-col items-center mt-5 md:mt-12">
          {/* Foreground Text */}
          <h1 className="text-2xl md:text-4xl font-semibold text-gray-100">
            Certificates
          </h1>
          {/* Custom Border */}
          <div className="md:w-28 w-14 md:h-[4px] h-[2px] bg-orange-600 mt-2"></div>
        </div>
      </div>
      {/* main content section */}
      <div className="px-10">
        <CertificatesSlider />
      </div>
    </div>
  );
};

export default Certificates;
