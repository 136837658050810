// import React from "react";

// const Portfolio = () => {
//   return (
//     <div className="font font-Poppins bg-[#343A40] min-h-screen pb-8">
//       {/* text overlay effect of main heading*/}
//       <div className="flex flex-col items-center relative pt-20">
//         {/* Background Text */}
//         <h1 className="absolute text-6xl md:text-9xl font-semibold text-white opacity-5">
//           PORTFOLIO
//         </h1>
//         <div className="relative flex flex-col items-center mt-5 md:mt-12">
//           {/* Foreground Text */}
//           <h1 className="text-2xl md:text-4xl font-semibold text-gray-100">
//             My Work
//           </h1>
//           {/* Custom Border */}
//           <div className="md:w-28 w-14 md:h-[4px] h-[2px] bg-orange-600 mt-2"></div>
//         </div>
//       </div>
//       {/* main content section */}
//       <div></div>
//     </div>
//   );
// };

// export default Portfolio;

import React from "react";
import portfolio from "../Assets/portfolio.png";
import stemschool from "../Assets/stem-school.png";
import carsweb from "../Assets/3D-cars-web.png";
import Admindashboard from "../Assets/Admin-dashboard.png";
import blog from "../Assets/blog.png";
import doctorswebsite from "../Assets/doctors-website.png";
import counter from "../Assets/counter-app.png";
import graphicsreview from "../Assets/graphics-review.png";
import gymstore from "../Assets/gym-store.png";
import pizzawebsite from "../Assets/pizza-website.png";
import wheather from "../Assets/wheather.png";
import pricingweb from "../Assets/pricing-web.png";

const projects = [
  {
    img: portfolio,
    title: "Portfolio",
    link: "https://portfolio-anum-shahanas-projects.vercel.app/",
  },
  {
    img: stemschool,
    title: "STEM School",
    link: "https://stemschool.example.com",
  },
  { img: carsweb, title: "3D Cars Web", link: "https://carsweb.example.com" },
  {
    img: Admindashboard,
    title: "Admin Dashboard",
    link: "https://admindashboard.example.com",
  },
  {
    img: blog,
    title: "Blog",
    link: "https://blogapplication-anum-shahanas-projects.vercel.app/",
  },
  {
    img: doctorswebsite,
    title: "Doctors Website",
    link: "https://doctorswebsite-anum-shahanas-projects.vercel.app/",
  },
  {
    img: counter,
    title: "Counter App",
    link: "https://counterapp.example.com",
  },
  {
    img: graphicsreview,
    title: "Graphics Review",
    link: "https://graphics-review-31a5q8ici-anum-shahanas-projects.vercel.app/",
  },
  { img: gymstore, title: "Gym Store", link: "https://gymstore.example.com" },
  {
    img: pizzawebsite,
    title: "Pizza Website",
    link: "https://pizzawebsite-anum-shahanas-projects.vercel.app/",
  },
  {
    img: wheather,
    title: "Weather App",
    link: "https://weatherapp-anum-shahanas-projects.vercel.app/",
  },
  {
    img: pricingweb,
    title: "Pricing Web",
    link: "https://pricingweb.example.com",
  },
];

const Portfolio = () => {
  return (
    <div className="font font-Poppins bg-[#343A40] min-h-screen pb-8">
      {/* text overlay effect of main heading*/}
      <div className="flex flex-col items-center relative pt-20">
        {/* Background Text */}
        <h1 className="absolute text-6xl md:text-9xl font-semibold text-white opacity-5">
          PORTFOLIO
        </h1>
        <div className="relative flex flex-col items-center mt-5 md:mt-12">
          {/* Foreground Text */}
          <h1 className="text-2xl md:text-4xl font-semibold text-gray-100">
            My Work
          </h1>
          {/* Custom Border */}
          <div className="md:w-28 w-14 md:h-[4px] h-[2px] bg-orange-600 mt-2"></div>
        </div>
      </div>
      {/* main content section */}
      <div className="px-8 mt-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {projects.map((project, index) => (
            <a
              key={index}
              href={project.link}
              target="_blank"
              rel="noopener noreferrer"
              className="relative overflow-hidden rounded-lg shadow-lg transform transition-transform hover:scale-105 text-white"
            >
              <img
                src={project.img}
                alt={project.title}
                className="w-full h-full object-cover transform transition-transform duration-300 hover:scale-110"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center opacity-0 transition-opacity duration-300 hover:opacity-100 text-center">
                <span className="text-lg font-bold">{project.title}</span>
                <span className="text-sm mt-2 text-orange-400">
                  View Project
                </span>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
