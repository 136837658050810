// import React from "react";

// const Contact = () => {
//   return (
// <div className="font font-Poppins bg-[#343A40] min-h-screen pb-8">
//   {/* text overlay effect of main heading */}
//   <div className="flex flex-col items-center relative pt-20 mb-20">
//     {/* Background Text */}
//     <h1 className="absolute text-6xl md:text-9xl font-semibold text-white opacity-5">
//       CONTACT
//     </h1>
//     <div className="relative flex flex-col items-center mt-5 md:mt-12">
//       {/* Foreground Text */}
//       <h1 className="text-2xl md:text-4xl font-semibold text-gray-100">
//         Get in Touch
//       </h1>
//       {/* Custom Border */}
//       <div className="md:w-28 w-14 md:h-[4px] h-[2px] bg-orange-600 mt-2"></div>
//     </div>
//   </div>
//   {/* main content section */}
//   <div></div>
// </div>
//   );
// };

// export default Contact;

import React from "react";
import { FaFacebookF, FaGithub, FaLinkedinIn } from "react-icons/fa"; // Import social media icons
import { IoLogoInstagram } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import { MdCall } from "react-icons/md";

const Contact = () => {
  return (
    <div className="font font-Poppins bg-[#212529] min-h-screen pb-48 md:pb-8 text-gray-100 overflow-x-auto">
      {/* Text overlay effect of main heading */}
      <div className="flex flex-col items-center relative pt-20 mb-20">
        {/* Background Text */}
        <h1 className="absolute text-6xl md:text-9xl font-semibold text-white opacity-5">
          CONTACT
        </h1>
        <div className="relative flex flex-col items-center mt-5 md:mt-12">
          {/* Foreground Text */}
          <h1 className="text-2xl md:text-4xl font-semibold text-gray-100">
            Get in Touch
          </h1>
          {/* Custom Border */}
          <div className="md:w-28 w-14 md:h-[4px] h-[2px] bg-orange-600 mt-2"></div>
        </div>
      </div>

      {/* Main content section */}
      <div className="flex flex-col md:flex-row justify-center items-start px-6 md:px-20 space-y-8 md:space-y-0 md:space-x-12">
        {/* Contact Form */}
        <div className="w-full md:w-1/2">
          <h2 className="text-2xl mb-4">Just say Hello</h2>
          <form className="space-y-4">
            <input
              type="text"
              placeholder="Your Name"
              className="w-full p-3 rounded bg-[#343d4b] text-gray-100 placeholder-gray-400"
            />
            <input
              type="email"
              placeholder="Your Email"
              className="w-full p-3 rounded bg-[#343d4b] text-gray-100 placeholder-gray-400"
            />
            <input
              type="text"
              placeholder="Your Subject"
              className="w-full p-3 rounded bg-[#343d4b] text-gray-100 placeholder-gray-400"
            />
            <textarea
              placeholder="Your Message"
              rows="4"
              className="w-full p-3 rounded bg-[#343d4b] text-gray-100 placeholder-gray-400"
            ></textarea>
            <button
              type="submit"
              className="px-6 py-3 bg-orange-600 text-white font-semibold rounded hover:bg-orange-500 transition duration-200"
            >
              Send Message
            </button>
          </form>
        </div>

        {/* Contact Information */}
        <div className="w-full md:w-1/2 space-y-4 ">
          <h2 className="text-2xl mb-4">Contact Info</h2>
          <p className="text-gray-400">
            Let’s turn your ideas into reality – get in touch today!
          </p>
          <div className="space-y-2">
            <div className="flex flex-col space-x-2">
              <div className="flex items-center text-orange-500 underline underline-offset-4">
                <MdOutlineEmail />
                <p className="ml-2">Email :</p>
              </div>
              <div>
                <p>anumshahana29@gmail.com</p>
              </div>
            </div>
            <div className="flex flex-col space-x-2">
              <div className="flex items-center text-orange-500 underline underline-offset-4">
                <MdCall />
                <p className="ml-2">Phone :</p>
              </div>
              <div>
                <p>+1 876-369-9009</p>
              </div>
            </div>
            <div className="flex flex-col space-x-2">
              <div className="flex items-center text-orange-500 underline underline-offset-4">
                <CiLocationOn />
                <p className="ml-2">Address :</p>
              </div>
              <div>
                <p>Peshawar, Pakistan</p>
              </div>
            </div>
          </div>

          <p className="mt-6 text-gray-400">
            Visit my social profile and get connected
          </p>
          <div className="flex items-center justify-center space-x-8 mt-2">
            <a
              href="#"
              className="text-white hover:text-blue-700 transition duration-200 p-3 rounded-full bg-blue-600 hover:bg-white"
            >
              <FaFacebookF />
            </a>

            <a
              href="#"
              className="text-gray-800 hover:text-white transition duration-200 p-3 rounded-full bg-gray-300 hover:bg-gray-600"
            >
              <FaGithub />
            </a>

            <a
              href="#"
              className="text-white hover:text-blue-700 transition duration-200 p-3 rounded-full bg-blue-500 hover:bg-white"
            >
              <FaLinkedinIn />
            </a>

            <a
              href="#"
              className="text-white bg-gradient-to-r from-pink-700 to-purple-500 hover:from-pink-800 hover:to-purple-600 transition duration-200 p-3 rounded-full"
            >
              <IoLogoInstagram />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
