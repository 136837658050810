// import React from "react";

// const HeroSection = () => {
//   return (
//     <div className="flex flex-col items-center justify-center h-screen  text-white font-Poppins">
//       <h1 className="text-2xl font-medium">Welcome</h1>
//       <h1 className="text-4xl font-medium">I'm a Web Devloper </h1>
//     </div>
//   );
// };

// export default HeroSection;

import React from "react";
import Typewriter from "typewriter-effect";

const HeroSection = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-white font-Poppins">
      <h1 className="text-2xl font-medium mb-4">Welcome</h1>
      <h1 className="text-3xl md:text-6xl font-bold">
        <Typewriter
          options={{
            strings: ["I'm a Web Developer"],
            autoStart: true,
            loop: true,
          }}
        />
      </h1>
      <p className="mt-4">based in Peshawar, Pakistan</p>
      <button className="hover:bg-orange-600 border-orange-600 border-2 mt-6 rounded-full px-8 py-4">
        Hire me
      </button>
    </div>
  );
};

export default HeroSection;
