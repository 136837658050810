// import React from "react";
// import sa2 from "../Assets/sa2.png";

// const Bot = () => {
//   const botStyles = {
//     "--df-messenger-bot-message": "#153448", //Bubble background color for agent messages.(bot response bg)
//     "--df-messenger-button-titlebar-color": "#153448", // (bot button)Color for the floating button and the titlebar of the chat dialog.
//     "--df-messenger-button-titlebar-font-color": "#F5EFE6", // (x button)Font color for the title in the titlebar.
//     "--df-messenger-chat-background-color": "#124b81c7", //(bot bg) Color for the chat dialog background.
//     "--df-messenger-font-color": "#F1F1F1", // Font color for messages.
//     "--df-messenger-input-box-color": "#153448", //Background color for the text input box.
//     "--df-messenger-input-font-color": "#F1F1F1", //Font color for the text input box.
//     "--df-messenger-input-placeholder-font-color": "#948979", //Font color for placeholder text in text input box.
//     "--df-messenger-minimized-chat-close-icon-color": "#F5EFE6", //Color of the close icon in the closed chat view.
//     "--df-messenger-send-icon": "#F5EFE6", //Color of the send icon in the text input box.
//     "--df-messenger-user-message": "#00215E", //Bubble background color for user messages.
//     "--df-messenger-chat-bubble-icon-size": "100px",
//     // "--df-messenger-chat-border": "", // Border for the dialog
//     // "--df-messenger-chat-border-radius": "", //Border radius for the dialog
//     // "--df-messenger-chat-window-height": "100px", //Height of the chat window
//     // "--df-messenger-chat-bubble-icon-size": "",
//     // "--df-messenger-chat-bubble-border": "", //Border of the chat bubble
//   };
//   return (
//     <div style={botStyles}>
//       <script src="https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1"></script>
//       <df-messenger
//         intent="WELCOME"
//         chat-title="PortfolioAgent"
//         agent-id="87ad01b9-df6a-4262-966a-1a2d49b8a749"
//         language-code="en"
//         chat-icon={sa2}
//         chat-bubble-icon-colorchat-background="#FFBF65"
//         // chat-bubble-icon-colorchat-background="#F96E2A"
//       ></df-messenger>
//       {/* <df-messenger
//         intent="WELCOME"
//         chat-title="PortfolioAgent"
//         agent-id="87ad01b9-df6a-4262-966a-1a2d49b8a749"
//         language-code="en"
//         chat-icon={sa2}
//         chat-bubble-icon-color="#F96E2A" // Corrected attribute
//         chat-background="#FFBF65" // If you want to set the chat background color
//       ></df-messenger> */}
//     </div>
//   );
// };

// export default Bot;

import React from "react";
import sa2 from "../Assets/sa2.png";

const Bot = () => {
  const botStyles = {
    "--df-messenger-bot-message": "#153448", //Bubble background color for agent messages (bot response bg)
    "--df-messenger-button-titlebar-color": "#EA580C", // Color beneath the chat icon (titlebar color when open)
    "--df-messenger-button-titlebar-font-color": "#F5EFE6", // Font color for title in titlebar.
    "--df-messenger-chat-background-color": "#124b81c7", // Chat dialog background color.
    "--df-messenger-font-color": "#F1F1F1", // Font color for messages.
    "--df-messenger-input-box-color": "#153448", // Background color for the text input box.
    "--df-messenger-input-font-color": "#F1F1F1", // Font color for the text input box.
    "--df-messenger-input-placeholder-font-color": "#948979", // Placeholder text color in input box.
    "--df-messenger-minimized-chat-close-icon-color": "#F5EFE6", // Close icon color in minimized view.
    "--df-messenger-send-icon": "#F5EFE6", // Send icon color in input box.
    "--df-messenger-user-message": "#00215E", // Background color for user messages.
  };

  return (
    <div style={botStyles}>
      <script src="https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1"></script>
      <df-messenger
        intent="WELCOME"
        chat-title="PortfolioAgent"
        agent-id="87ad01b9-df6a-4262-966a-1a2d49b8a749"
        language-code="en"
        chat-icon={sa2}
      ></df-messenger>
    </div>
  );
};

export default Bot;
