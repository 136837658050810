// import React from "react";

// const AboutMe = () => {
//   return (
//     <div className="font font-Poppins flex items-center justify-center h-screen bg-[#212529] relative">
//       {/* Background Text */}
//       <h1 className="absolute text-9xl font-semibold text-white opacity-10">
//         ABOUT ME
//       </h1>
//       <div className="relative flex flex-col items-center justify-center">
//         {/* Foreground Text */}
//         <h1 className=" text-4xl font-semibold text-gray-100">Know Me More</h1>
//         {/* Custom Border */}
//         <div className=" w-28 h-[4px] bg-orange-600  mt-2 "></div>
//       </div>
//     </div>
//   );
// };

// export default AboutMe;

// import React from "react";

// const AboutMe = () => {
//   return (
//     <div className="font font-Poppins  bg-[#212529]  min-h-screen">
//       {/* text overlay effect */}
//       <div className="flex flex-col items-center relative pt-20">
//         {/* Background Text */}
//         <h1 className="absolute text-6xl md:text-9xl font-semibold text-white opacity-5  ">
//           ABOUT ME
//         </h1>
//         <div className="relative flex flex-col items-center mt-5 md:mt-10">
//           {/* Foreground Text */}
//           <h1 className="text-2xl md:text-4xl  font-semibold text-gray-100">
//             Know Me More
//           </h1>
//           {/* Custom Border */}
//           <div className="md:w-28 w-14 md:h-[4px] h-[2px] bg-orange-600 mt-2"></div>
//         </div>
//       </div>
//       {/* main content section */}
//       <div className="flex  flex-col md:flex-row  text-white md:px-10 mt-20 space-x-16">
//         {/* paragraph section */}
//         <div className="md:w-3/5 w-full ">
//           <h1 className="text-2xl">
//             I'm <span className="text-orange-600">Anum Shahana,</span> a Web
//             Developer
//           </h1>
//           <p className="text-sm mt-2">
//             I am a Frontend Developer who enjoys turning ideas to life through
//             engaging and user-friendly web applications. My journey in
//             technology began with a Bachelor's degree in Computer Science from
//             the Virtual University of Pakistan, followed by a transformative
//             bootcamp experience at AtomCamp, where I honed my skills in
//             full-stack development. Now, as a Frontend Developer at Digitalized
//             Solutions, I have advanced from an intern to a full-time role,
//             earning recognition as the "Best Frontend Developer" for my
//             contributions. My expertise lies in leveraging frameworks like
//             React.js and Next.js, along with Tailwind CSS, to create responsive
//             designs that not only meet client needs but also enhance user
//             experience. I am constantly inspired by the ever-evolving nature of
//             web development and am committed to learning and growing in this
//             dynamic field, with a focus on delivering high-quality, innovative
//             solutions.
//           </p>
//         </div>
//         {/* name section */}

//         <div className="md:w-2/5 w-full">
//           {/* name */}
//           <h1>
//             <span className="font-bold mr-2">Name:</span> Anum Shahana
//           </h1>
//           <div className=" w-full h-[1px] mt-4 mb-4 bg-gray-400 "></div>
//           {/* email */}
//           <h1>
//             <span className="font-bold mr-2">Email:</span>
//             <span className="text-orange-600">anumshahana29@gmail.com</span>
//           </h1>
//           <div className=" w-full h-[1px] mt-4 mb-4 bg-gray-400"></div>
//           {/* Age */}
//           <h1>
//             <span className="font-bold mr-2">Age:</span> 28
//           </h1>
//           <div className=" w-full h-[1px] mt-4 mb-4 bg-gray-400"></div>
//           {/* location */}
//           <h1>
//             <span className="font-bold mr-2">From:</span> pakistan
//           </h1>
//           <div className=" w-full h-[1px] mt-4 mb-4 bg-gray-400"></div>
//           {/* button  */}
//           <button className="bg-orange-600 hover:bg-orange-800 mt-4 rounded-full px-8 py-4">
//             Download CV
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AboutMe;

import React from "react";

const AboutMe = () => {
  return (
    <div className="font font-Poppins bg-[#212529] min-h-screen pb-8">
      {/* text overlay effect of main heading*/}
      <div className="flex flex-col items-center relative pt-20">
        {/* Background Text */}
        <h1 className="absolute text-6xl md:text-9xl font-semibold text-white opacity-5">
          ABOUT ME
        </h1>
        <div className="relative flex flex-col items-center mt-5 md:mt-10">
          {/* Foreground Text */}
          <h1 className="text-2xl md:text-4xl font-semibold text-gray-100">
            Know Me More
          </h1>
          {/* Custom Border */}
          <div className="md:w-28 w-14 md:h-[4px] h-[2px] bg-orange-600 mt-2"></div>
        </div>
      </div>
      {/* main content section */}
      <div className="flex flex-col md:flex-row text-white px-5 md:px-10 mt-20 space-y-8 md:space-y-0 md:space-x-16">
        {/* paragraph section */}
        <div className="md:w-3/5 w-full">
          <h1 className="text-2xl">
            I'm <span className="text-orange-600">Anum Shahana,</span> a Web
            Developer
          </h1>
          <p className="text-sm mt-2">
            I am a Frontend Developer who enjoys turning ideas to life through
            engaging and user-friendly web applications. My journey in
            technology began with a Bachelor's degree in Computer Science from
            the Virtual University of Pakistan, followed by a transformative
            bootcamp experience at AtomCamp, where I honed my skills in
            full-stack development. Now, as a Frontend Developer at Digitalized
            Solutions, I have advanced from an intern to a full-time role,
            earning recognition as the "Best Frontend Developer" for my
            contributions. My expertise lies in leveraging frameworks like
            React.js and Next.js, along with Tailwind CSS, to create responsive
            designs that not only meet client needs but also enhance user
            experience. I am constantly inspired by the ever-evolving nature of
            web development and am committed to learning and growing in this
            dynamic field, with a focus on delivering high-quality, innovative
            solutions.
          </p>
        </div>
        {/* name section */}
        <div className="md:w-2/5 w-full">
          {/* name */}
          <h1>
            <span className="font-bold mr-2">Name:</span> Anum Shahana
          </h1>
          <div className="w-full h-[1px] mt-4 mb-4 bg-gray-400"></div>
          {/* email */}
          <h1>
            <span className="font-bold mr-2">Email:</span>
            <span className="text-orange-600"> anumshahana29@gmail.com</span>
          </h1>
          <div className="w-full h-[1px] mt-4 mb-4 bg-gray-400"></div>
          {/* Age */}
          <h1>
            <span className="font-bold mr-2"> LinkedIn:</span>
            linkedin.com/in/anumshahana
          </h1>
          <div className="w-full h-[1px] mt-4 mb-4 bg-gray-400"></div>
          {/* location */}
          <h1>
            <span className="font-bold mr-2">From:</span> Pakistan
          </h1>
          <div className="w-full h-[1px] mt-4 mb-4 bg-gray-400"></div>
          {/* button */}
          <button className="bg-orange-600 hover:bg-orange-800 mt-4 rounded-full px-8 py-4">
            Download CV
          </button>
        </div>
      </div>
      {/* stats section */}
      <div className="p-4 md:p-6 text-white">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 lg:w-1/4 py-8">
            <div className="md:border-r border-gray-200 px-12">
              <h2 className="text-4xl text-[#909294] md:text-5xl font-semibold text-center">
                2+
              </h2>
              <p className=" mb-2 text-center">Years Experiance</p>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4 py-8">
            <div className="lg:border-r border-gray-200 px-12">
              <h2 className="text-4xl text-[#909294] md:text-5xl  font-semibold text-center">
                10+
              </h2>
              <p className=" mb-2 text-center">Happy Clients</p>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4 py-8">
            <div className="md:border-r border-gray-200 px-12">
              <h2 className="text-4xl text-[#909294] md:text-5xl  font-semibold text-center">
                20+
              </h2>
              <p className=" mb-2 text-center">Projects Done</p>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4 py-8">
            <div className="px-12">
              <h2 className="text-4xl text-[#909294] md:text-5xl  font-semibold text-center">
                10+
              </h2>
              <p className=" mb-2 text-center">Get Awards</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
