// import React, { Component } from "react";
// import Slider from "react-slick";
// import man1 from "../Assets/man1.png";

// function VerticalMode() {
//   const settings = {
//     dots: true,

//     infinite: true,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     vertical: true,
//     verticalSwiping: true,
//     beforeChange: function (currentSlide, nextSlide) {
//       console.log("before change", currentSlide, nextSlide);
//     },
//     afterChange: function (currentSlide) {
//       console.log("after change", currentSlide);
//     },
//     autoplay: true,
//     speed: 2000,
//     autoplaySpeed: 2000,
//   };
//   return (
//     <div className="slider-container">
//       <Slider {...settings}>
//         {/* slide 1 */}
//         <div>
//           <div className="flex flex-col sm:flex-row bg-gray-200 items-center max-h-auto max-w-full sm:max-w-lg lg:max-w-2xl mt-10 mx-auto  shadow-xl rounded-l-full rounded-xl overflow-hidden">
//             {/* <!-- img and text --> */}
//             <div className="flex items-center w-full">
//               <div className="w-full sm:w-1/3 md:w-1/4 lg:w-1/4">
//                 <img
//                   className="w-full h-full object-cover rounded-full border-4 border-gray-400"
//                   src="https://s3.amazonaws.com/media.mixrank.com/profilepic/f7f1c03d77cd5788451a59d0c7eecabd"
//                   alt="Profile Picture"
//                 />
//               </div>
//               <div className="flex-1 pl-4 sm:pl-6 space-y-2">
//                 <h3 className="text-sm sm:text-base md:text-base lg:text-xl font-semibold text-gray-800">
//                   Sumit Kapoor hello
//                 </h3>
//                 <p className="text-xs sm:text-sm md:text-base text-gray-500">
//                   Lorem ipsum is a dummy or placeholder text commonly used in
//                   graphic design, publishing, and web development to fill empty
//                   spaces in a layout that do not yet have content.
//                 </p>

//                 {/* <!-- rating stars --> */}
//                 <div className="flex items-center">
//                   <svg
//                     className="w-4 h-4 text-yellow-300"
//                     aria-hidden="true"
//                     xmlns="http://www.w3.org/2000/svg"
//                     fill="currentColor"
//                     viewBox="0 0 22 20"
//                   >
//                     <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//                   </svg>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* slide 2 */}
//         <div>
//           <div className="flex flex-col sm:flex-row bg-gray-200 items-center max-h-auto max-w-full sm:max-w-lg lg:max-w-2xl mt-10 mx-auto  shadow-xl rounded-l-full rounded-xl overflow-hidden">
//             {/* <!-- img and text --> */}
//             <div className="flex items-center w-full">
//               <div className="w-full sm:w-1/3 md:w-1/4 lg:w-1/4">
//                 <img
//                   className="w-full h-full object-cover rounded-full border-4 border-gray-400"
//                   src="https://s3.amazonaws.com/media.mixrank.com/profilepic/f7f1c03d77cd5788451a59d0c7eecabd"
//                   alt="Profile Picture"
//                 />
//               </div>
//               <div className="flex-1 pl-4 sm:pl-6 space-y-2">
//                 <h3 className="text-sm sm:text-base md:text-base lg:text-xl font-semibold text-gray-800">
//                   Sumit Kapoor hello
//                 </h3>
//                 <p className="text-xs sm:text-sm md:text-base text-gray-500">
//                   Lorem ipsum is a dummy or placeholder text commonly used in
//                   graphic design, publishing, and web development to fill empty
//                   spaces in a layout that do not yet have content.
//                 </p>

//                 {/* <!-- rating stars --> */}
//                 <div className="flex items-center">
//                   <svg
//                     className="w-4 h-4 text-yellow-300"
//                     aria-hidden="true"
//                     xmlns="http://www.w3.org/2000/svg"
//                     fill="currentColor"
//                     viewBox="0 0 22 20"
//                   >
//                     <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//                   </svg>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* slide 3 */}
//         <div>
//           <div className="flex flex-col sm:flex-row bg-gray-200 items-center max-h-auto max-w-full sm:max-w-lg lg:max-w-2xl mt-10 mx-auto  shadow-xl rounded-l-full rounded-xl overflow-hidden">
//             {/* <!-- img and text --> */}
//             <div className="flex items-center w-full">
//               <div className="w-full sm:w-1/3 md:w-1/4 lg:w-1/4">
//                 <img
//                   className="w-full h-full object-cover rounded-full border-4 border-gray-400"
//                   src="https://s3.amazonaws.com/media.mixrank.com/profilepic/f7f1c03d77cd5788451a59d0c7eecabd"
//                   alt="Profile Picture"
//                 />
//               </div>
//               <div className="flex-1 pl-4 sm:pl-6 space-y-2">
//                 <h3 className="text-sm sm:text-base md:text-base lg:text-xl font-semibold text-gray-800">
//                   Sumit Kapoor hello
//                 </h3>
//                 <p className="text-xs sm:text-sm md:text-base text-gray-500">
//                   Lorem ipsum is a dummy or placeholder text commonly used in
//                   graphic design, publishing, and web development to fill empty
//                   spaces in a layout that do not yet have content.
//                 </p>

//                 {/* <!-- rating stars --> */}
//                 <div className="flex items-center">
//                   <svg
//                     className="w-4 h-4 text-yellow-300"
//                     aria-hidden="true"
//                     xmlns="http://www.w3.org/2000/svg"
//                     fill="currentColor"
//                     viewBox="0 0 22 20"
//                   >
//                     <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//                   </svg>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* slide 4 */}
//         <div>
//           <div className="flex flex-col sm:flex-row bg-gray-200 items-center max-h-auto max-w-full sm:max-w-lg lg:max-w-2xl mt-10 mx-auto  shadow-xl rounded-l-full rounded-xl overflow-hidden">
//             {/* <!-- img and text --> */}
//             <div className="flex items-center w-full">
//               <div className="w-full sm:w-1/3 md:w-1/4 lg:w-1/4">
//                 <img
//                   className="w-full h-full object-cover rounded-full border-4 border-gray-400"
//                   src="https://s3.amazonaws.com/media.mixrank.com/profilepic/f7f1c03d77cd5788451a59d0c7eecabd"
//                   alt="Profile Picture"
//                 />
//               </div>
//               <div className="flex-1 pl-4 sm:pl-6 space-y-2">
//                 <h3 className="text-sm sm:text-base md:text-base lg:text-xl font-semibold text-gray-800">
//                   Sumit Kapoor hello
//                 </h3>
//                 <p className="text-xs sm:text-sm md:text-base text-gray-500">
//                   Lorem ipsum is a dummy or placeholder text commonly used in
//                   graphic design, publishing, and web development to fill empty
//                   spaces in a layout that do not yet have content.
//                 </p>

//                 {/* <!-- rating stars --> */}
//                 <div className="flex items-center">
//                   <svg
//                     className="w-4 h-4 text-yellow-300"
//                     aria-hidden="true"
//                     xmlns="http://www.w3.org/2000/svg"
//                     fill="currentColor"
//                     viewBox="0 0 22 20"
//                   >
//                     <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//                   </svg>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* slide 5 */}
//         <div>
//           <div className="flex flex-col sm:flex-row bg-gray-200 items-center max-h-auto max-w-full sm:max-w-lg lg:max-w-2xl mt-10 mx-auto  shadow-xl rounded-l-full rounded-xl overflow-hidden">
//             {/* <!-- img and text --> */}
//             <div className="flex items-center w-full">
//               <div className="w-full sm:w-1/3 md:w-1/4 lg:w-1/4">
//                 <img
//                   className="w-full h-full object-cover rounded-full border-4 border-gray-400"
//                   src="https://s3.amazonaws.com/media.mixrank.com/profilepic/f7f1c03d77cd5788451a59d0c7eecabd"
//                   alt="Profile Picture"
//                 />
//               </div>
//               <div className="flex-1 pl-4 sm:pl-6 space-y-2">
//                 <h3 className="text-sm sm:text-base md:text-base lg:text-xl font-semibold text-gray-800">
//                   Sumit Kapoor hello
//                 </h3>
//                 <p className="text-xs sm:text-sm md:text-base text-gray-500">
//                   Lorem ipsum is a dummy or placeholder text commonly used in
//                   graphic design, publishing, and web development to fill empty
//                   spaces in a layout that do not yet have content.
//                 </p>

//                 {/* <!-- rating stars --> */}
//                 <div className="flex items-center">
//                   <svg
//                     className="w-4 h-4 text-yellow-300"
//                     aria-hidden="true"
//                     xmlns="http://www.w3.org/2000/svg"
//                     fill="currentColor"
//                     viewBox="0 0 22 20"
//                   >
//                     <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//                   </svg>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* slide 6 */}
//         <div>
//           <div className="flex flex-col sm:flex-row bg-gray-200 items-center max-h-auto max-w-full sm:max-w-lg lg:max-w-2xl mt-10 mx-auto  shadow-xl rounded-l-full rounded-xl overflow-hidden">
//             {/* <!-- img and text --> */}
//             <div className="flex items-center w-full">
//               <div className="w-full sm:w-1/3 md:w-1/4 lg:w-1/4">
//                 <img
//                   className="w-full h-full object-cover rounded-full border-4 border-gray-400"
//                   src="https://s3.amazonaws.com/media.mixrank.com/profilepic/f7f1c03d77cd5788451a59d0c7eecabd"
//                   alt="Profile Picture"
//                 />
//               </div>
//               <div className="flex-1 pl-4 sm:pl-6 space-y-2">
//                 <h3 className="text-sm sm:text-base md:text-base lg:text-xl font-semibold text-gray-800">
//                   Sumit Kapoor hello
//                 </h3>
//                 <p className="text-xs sm:text-sm md:text-base text-gray-500">
//                   Lorem ipsum is a dummy or placeholder text commonly used in
//                   graphic design, publishing, and web development to fill empty
//                   spaces in a layout that do not yet have content.
//                 </p>

// {/* <!-- rating stars --> */}
// <div className="flex items-center">
//   <svg
//     className="w-4 h-4 text-yellow-300"
//     aria-hidden="true"
//     xmlns="http://www.w3.org/2000/svg"
//     fill="currentColor"
//     viewBox="0 0 22 20"
//   >
//     <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
//   </svg>
// </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </Slider>
//     </div>
//   );
// }

// export default VerticalMode;

import React from "react";
import man1 from "../Assets/man1.png";
import man2 from "../Assets/man2.png";

const VerticalCardSlider = () => {
  return (
    <div className="wrapper">
      <div className="outer">
        {/* card  1*/}
        <div className="card" style={{ "--delay": "-1" }}>
          <div className="content">
            <div className="img">
              <img src={man1} alt="Sumit Kapoor" />
            </div>
            <div className="details">
              <span className="name">Sumit Kapoor</span>
              <p className="text-sm">
                Highly skilled web developer, great results every time!
              </p>
            </div>
          </div>
          {/* <!-- rating stars --> */}
          <div className="flex items-center">
            <svg
              className="w-4 h-4 text-yellow-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
            <svg
              className="w-4 h-4 text-yellow-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
            <svg
              className="w-4 h-4 text-yellow-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
          </div>
        </div>
        {/* card  2*/}
        <div className="card" style={{ "--delay": "0" }}>
          <div className="content">
            <div className="img">
              <img src={man2} alt="Andrew Neil" />
            </div>
            <div className="details">
              <span className="name">Andrew Neil</span>
              <p className="text-sm">
                Highly skilled web developer, great results every time!
              </p>
            </div>
          </div>
          {/* <!-- rating stars --> */}
          <div className="flex items-center">
            <svg
              className="w-4 h-4 text-yellow-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
            <svg
              className="w-4 h-4 text-yellow-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
            <svg
              className="w-4 h-4 text-yellow-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
          </div>
        </div>
        {/* card  3*/}
        <div className="card" style={{ "--delay": "1" }}>
          <div className="content">
            <div className="img">
              <img src={man1} alt="Jasmine Carter" />
            </div>
            <div className="details">
              <span className="name">Jasmine Carter</span>
              <p className="text-sm">
                Highly skilled web developer, great results every time!
              </p>
            </div>
          </div>
          {/* <!-- rating stars --> */}
          <div className="flex items-center">
            <svg
              className="w-4 h-4 text-yellow-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
            <svg
              className="w-4 h-4 text-yellow-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
            <svg
              className="w-4 h-4 text-yellow-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
          </div>
        </div>
        {/* card  4*/}
        <div className="card" style={{ "--delay": "2" }}>
          <div className="content">
            <div className="img">
              <img src={man2} alt="Justin Chung" />
            </div>
            <div className="details">
              <span className="name">Justin Chung</span>
              <p className="text-sm">
                Highly skilled web developer, great results every time!
              </p>
            </div>
          </div>
          {/* <!-- rating stars --> */}
          <div className="flex items-center">
            <svg
              className="w-4 h-4 text-yellow-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
            <svg
              className="w-4 h-4 text-yellow-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
            <svg
              className="w-4 h-4 text-yellow-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
          </div>
        </div>
        {/* card  5*/}
        <div className="card" style={{ "--delay": "3" }}>
          <div className="content">
            <div className="img">
              <img src={man1} alt="Adrina Calvo" />
            </div>
            <div className="details">
              <span className="name">Adrina Calvo</span>
              <p className="text-sm">
                Highly skilled web developer, great results every time!
              </p>
            </div>
          </div>
          {/* <!-- rating stars --> */}
          <div className="flex items-center">
            <svg
              className="w-4 h-4 text-yellow-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
            <svg
              className="w-4 h-4 text-yellow-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
            <svg
              className="w-4 h-4 text-yellow-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalCardSlider;
